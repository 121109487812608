











import Vue, {PropOptions} from 'vue';
import ChemicalLatex from '../displayers/ChemicalLatex.vue';

export default Vue.extend({
  name: 'MoDiagramElectronInput',
  components: {ChemicalLatex},
  props: {
    value: {
      type: Number,
      default: null,
    } as PropOptions<number>,
  },
  data() {
    return {
      arrowLatex: ['', '\\upharpoonleft', '\\upharpoonleft\\downharpoonright'],
    };
  },
  methods: {
    cycleOptions() {
      this.$emit('input', this.value >= 2 ? 0 : this.value + 1);
    },
  },
});
