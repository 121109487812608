<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Answer each of the following questions about the heteronuclear diatomic molecule,
        <stemble-latex :content="molecule" />.
      </p>

      <p class="mb-2">
        a) Complete the molecular orbital diagram for
        <stemble-latex :content="molecule" />
        below. Electrons can be added to the orbitals by clicking the orbital. Additional clicks
        will change the occupation of the orbital. Be sure to fill in both the atomic and molecular
        orbitals.
        <b>Note: atomic orbitals are not shown</b>.
      </p>

      <mo-diagram-input1 v-model="inputs.MOinputs" :disabled="!allowEditing" />
      <div
        style="
          margin-top: 6px;
          padding: 0 18%;
          max-width: 650px;
          font-size: 1.4rem;
          display: flex;
          justify-content: space-between;
        "
      >
        <stemble-latex :content="'$\\textbf{' + atom1 + '}$'" responsive />
        <stemble-latex :content="'$\\textbf{' + atom2 + '}$'" responsive />
      </div>

      <p class="mt-10 mb-2">
        b) Using your molecular orbital diagram in part a), determine the bond order of the
        molecule.
      </p>

      <calculation-input
        v-model="inputs.bondOrder"
        class="mb-3"
        prepend-text="$\text{Bond Order}$"
        :disabled="!allowEditing"
      />

      <p class="mt-10 mb-2">
        c) Do you expect the molecule to be paramagnetic or diamagnetic based on your molecular
        orbital diagram?
      </p>

      <v-radio-group v-model="inputs.magnetism" class="pl-6" :disabled="!allowEditing">
        <v-radio
          v-for="option in options1"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
          row
        >
          <template #label>
            <stemble-latex :content="option.expression" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import MoDiagramInput1 from '../inputs/MoDiagramInput1';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question271',
  components: {
    MoDiagramInput1,
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        MOinputs: {
          input1: 0,
          input2: 0,
          input3: 0,
          input4: 0,
          input5: 0,
          input6: 0,
          input7: 0,
          input8: 0,
          // input9: 0,
          // input10: 0,
          // input11: 0,
          // input12: 0,
          // input13: 0,
          // input14: 0,
          // input15: 0,
          // input16: 0,
        },
        bondOrder: null,
        magnetism: null,
      },
      options1: [
        {expression: '$\\text{Paramagnetic}$', value: 'para'},
        {expression: '$\\text{Diamagnetic}$', value: 'dia'},
      ],
    };
  },
  computed: {
    versionNumber() {
      return this.taskState.getValueBySymbol('versionNumber').content;
    },
    versionData() {
      switch (this.versionNumber.value) {
        case 1:
          // 1 = NO
          return {
            molecule: '$\\ce{NO}$',
            atom1: 'N',
            atom2: 'O',
          };
        case 2:
          // 2 = NO+
          return {
            molecule: '$\\ce{NO+}$',
            atom1: 'N',
            atom2: 'O',
          };
        case 3:
          // 3 = NO-
          return {
            molecule: '$\\ce{NO-}$',
            atom1: 'N',
            atom2: 'O',
          };
        default:
          return {
            molecule: null,
            atom1: null,
            atom2: null,
          };
      }
    },
    molecule() {
      return this.versionData.molecule;
    },
    atom1() {
      return this.versionData.atom1;
    },
    atom2() {
      return this.versionData.atom2;
    },
  },
};
</script>
