













import Vue, {PropOptions} from 'vue';
import MoDiagramElectronInput from '@/tasks/components/inputs/MoDiagramElectronInput.vue';

interface MOInput {
  [inputKey: string]: number;
}

export default Vue.extend({
  name: 'MoDiagramInput1',
  components: {MoDiagramElectronInput},
  inheritAttrs: false,
  props: {
    value: {
      type: Object,
      default: () => ({
        input1: 0,
        input2: 0,
        input3: 0,
        input4: 0,
        input5: 0,
        input6: 0,
        input7: 0,
        input8: 0,
        // input9: 0,
        // input10: 0,
        // input11: 0,
        // input12: 0,
        // input13: 0,
        // input14: 0,
        // input15: 0,
        // input16: 0,
      }),
    } as PropOptions<MOInput>,
  },
  data() {
    return {
      clonedDiagramInputs: JSON.parse(JSON.stringify(this.value)) as MOInput,
    };
  },
  computed: {
    inputKeys(): string[] {
      return this.value ? Object.keys(this.clonedDiagramInputs) : [];
    },
  },
});
